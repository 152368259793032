// Styles
import styles from "./Resume.module.css";
// Utils
import { addComma } from "utils/utils";
// Libraries
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
// REDUX
import { useSelector } from "react-redux";

export default function Resume(props) {
  const { isModal, dataSimulatorCredit } = props;
  const [windowWidth, setWindowHeight] = useState(window.screen.width);
  let userReducer = useSelector((state) => state.userReducer)

  console.log('dataSimulatorCredit', dataSimulatorCredit)

  function tooltip(id_text, text) {
    return (
      <ReactTooltip
        place="top"
        id={`${id_text}`}
        aria-haspopup="true"
      >
        <div className={styles.tooltip}>
          {text}
        </div>
      </ReactTooltip>
    )
  }

  useEffect(() => {
    function updateSize() {
      setWindowHeight(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <>
      <div className={styles.container_15}>
        <h5
          className={windowWidth > 800 ? styles.biweekly_fee : styles.biweekly_fee_responsive}
        >
          **Tu cuota quincenal sera de** <br />
          <strong>${addComma(parseInt(dataSimulatorCredit.monthlyCost / 2))}</strong>
        </h5>
      </div>
      {/* } */}
      <div className={styles.Container} style={{ background: props.background }}>
        {isModal && <p className={styles.title}>Resumen</p>}
        <div>
          <h5
            style={{
              fontFamily: "var(--FONT_BOLD)",
              color: isModal && "var(--DARK_BLUE)",
            }}
          >
            Total a pagar
          </h5>
          <h5
            style={{
              fontFamily: "var(--FONT_BOLD)",
              color: isModal && "var(--DARK_BLUE)",
            }}
          >
            ${addComma(dataSimulatorCredit.total)} COP
          </h5>
        </div>
        <div style={{ color: isModal && "#333" }}>
          <p>Monto solicitado</p>
          <p>${addComma(dataSimulatorCredit.requestedAmount)} COP</p>
        </div>
        <div style={{ color: isModal && "#333" }}>
          <p>Intereses <FaInfoCircle data-tip data-for="interests" className={styles.icon} /></p>
          <p>${addComma(dataSimulatorCredit.interestPaid)} COP</p>
        </div>
        <div style={{ color: isModal && "#333" }}>
          <p>Aval + IVA <FaInfoCircle data-tip data-for="endorsement" className={styles.icon} /></p>
          <p>${addComma(dataSimulatorCredit.valueEndorsement)} COP</p>
        </div>
        <div style={{ color: isModal && "#333" }}>
          <p>Administración <FaInfoCircle data-tip data-for="platform" className={styles.icon} /></p>
          <p>${addComma(dataSimulatorCredit.valueCreditStudy)} COP</p>
        </div>
        <div style={{ color: isModal && "#333" }}>
          <p>IVA administración <FaInfoCircle data-tip data-for="VATplatform" className={styles.icon} /></p>
          <p>${addComma(dataSimulatorCredit.VATCreditStudy)} COP</p>
        </div>
        <div>
          <h5
            style={{
              fontFamily: "var(--FONT_BOLD)",
              color: isModal && "var(--DARK_BLUE)",
            }}
          >
            Total
          </h5>
          <p style={{ color: isModal && "var(--DARK_BLUE)" }}>
            ${addComma(dataSimulatorCredit.total)} COP
          </p>
        </div>
        {tooltip("platform", `Este cobro es único y se realiza al momento 
        del desembolso; te permite hacer tu solicitud 100% digital, sin 
        necesidad de desplazarte, pagar costos notariales de documentos, 
        y hacer largas filas.`)}
        {tooltip("VATplatform", `El 19% del costo de la administración, de 
        acuerdo a la normatividad colombiana.`)}
        {tooltip("endorsement", `Este cobro es único y se realiza al momento 
        del desembolso, funciona como garantía de pago, hace las veces de 
        codeudor como una forma de garantizar o asegurar el cumplimiento 
        de obligaciones económicas. `)}
        {tooltip("interests", `La tasa de interés es el precio del dinero, 
        el cual debes pagar por tomar un préstamo, actualmente el cobro de
        interés que vamos a cobrar por tú préstamo es de ${(userReducer.info_credit.interest_rate * 100).toFixed(2)}% Tasa mensual 
        efectiva, que equivale a 28.17% Tasa Anual Efectiva (Aprox). La tasa de mora 
        no superará la máxima permitida por la ley (SFC).`)}
      </div>
    </>
  );
}
